import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Dropdown,
  DropdownButton,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { notify } from '../../../components/Toast/Toast';
import { useProjectService } from '../../../services/ProjectsService';
import { MCHammer } from '../../../components/Loader';
import useAuth from '../../../hooks/useAuth';
import {
  IProjectHeaderContent,
  IProjectHeaderNavLinkObject,
  ProjectToSwitchContent,
} from './models';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  isDesktop,
  PERMISSION_CI_DI,
  PERMISSION_CPI,
  PERMISSION_MONETIZATION,
  PERMISSION_NOTIFICATIONS,
  PERMISSION_PROJECT_SETTINGS,
  PERMISSION_READ_EXPERIMENTS,
  PERMISSION_SAYKIT_SETTINGS,
  PERMISSION_SUPPORT,
  prettifyName,
  SecondaryColor,
} from '../../../services/_utils';
import {
  filterProjectsToSwitch,
  makeHeaderProjectsFilterField,
} from './_utils';
import { BrowserChrome, PcDisplay } from 'react-bootstrap-icons';
import { AppleIcon } from '../../../components/AppleIcon/AppleIcon';
import { AndroidIcon } from '../../../components/AndroidIcon/AndroidIcon';
import { ChinaIcon } from '../../../components/ChinaIcon/ChinaIcon';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { SearchInputComponent } from '../../../components/SearchInput';

export const ProjectCtx = createContext({} as IProjectHeaderContent);

const ProjectHeader: React.FC = () => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const { loading, error, getProjectHeader } = useProjectService();

  const [projectHeader, setProjectHeader] =
    useState<IProjectHeaderContent | null>();
  const [projectsToSwitch, setProjectsToSwitch] = useState(
    [] as ProjectToSwitchContent[]
  );

  useEffect(() => {
    setProjectHeader(null);
    getProjectHeader(projectId!).then((resp) => {
      setProjectHeader(resp.header);
      setProjectsToSwitch(makeHeaderProjectsFilterField(resp.projectsToSwitch));
    });
  }, [projectId]);

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  const mainClassname = classNames({
    'container-xxl':
      !pathname.includes('/compare-versions') &&
      !pathname.includes('/device_logs'),
    'container-fluid':
      pathname.includes('/compare-versions') ||
      pathname.includes('/device_logs'),
  });

  return (
    <>
      {loading && !projectHeader && <MCHammer />}
      {projectHeader && (
        <div className={mainClassname}>
          <ProjectCtx.Provider value={projectHeader}>
            <GameSwitcherView projectsToSwitch={projectsToSwitch} />
            <NavbarView />
            <Outlet />
          </ProjectCtx.Provider>
        </div>
      )}
    </>
  );
};

export default ProjectHeader;

const GameSwitcherView: React.FC<{
  projectsToSwitch: ProjectToSwitchContent[];
}> = ({ projectsToSwitch }) => {
  const { projectId } = useParams();
  const projectHeader = useContext(ProjectCtx);

  const [projectSwitcherSearch, setProjectSwitcherSearch] = useState('');
  const [hoverStoreLink, setHoverStoreLink] = useState(false);

  const title = () => (
    <h2 className="d-flex align-items-center m-0 pe-2">
      <img
        className="appIcon me-2 ms-1"
        src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
          projectHeader.appKey
        }&return_empty_on_error=true&size=small${
          projectHeader.china ? '&country=cn' : ''
        }`}
        alt={`${projectHeader.appKey}_icon`}
        height={36}
        width={36}
      />
      <div className="me-1">
        {isDesktop() ? (
          projectHeader.name
        ) : (
          <div>
            {prettifyName(projectHeader.name).length > 20 ? (
              <>
                {prettifyName(projectHeader.name).substring(0, 20)}
                ...
              </>
            ) : (
              <>{prettifyName(projectHeader.name)}</>
            )}
          </div>
        )}
      </div>
      {projectHeader.platform === 'pc' && (
        <PcDisplay className="ms-2" size={25} color={SecondaryColor} />
      )}
      {projectHeader.platform === 'ios' && <AppleIcon className="" size={28} />}
      {projectHeader.platform === 'android' && (
        <AndroidIcon className="" size={32} />
      )}
      {projectHeader.platform === 'web' && (
        <BrowserChrome className="" size={26} color={SecondaryColor} />
      )}
      {projectHeader.china && <ChinaIcon className="" size={32} />}
    </h2>
  );

  useEffect(() => {
    setProjectSwitcherSearch('');
  }, [projectId]);

  return (
    <StyledGameSwitcher>
      <div className="d-flex align-items-start">
        <DropdownButton
          data-testid="ddlProjectHeaderGameSwitcher"
          className="selectableDropdown d-inline-block"
          variant="outline-secondary"
          title={title()}
        >
          {projectsToSwitch
            .filter((p) => projectHeader?.gamePack.includes(p.appKey))
            .map((p) => (
              <div key={p.id}>
                <DropdownItem
                  data-testid={`lnkProjectHeaderGameSwitcher${p.id}`}
                  as={Link}
                  to={`/projects/${p.id}`}
                  className="d-inline-flex align-items-center"
                >
                  <img
                    className="appIcon me-2"
                    src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
                      p.appKey
                    }&return_empty_on_error=true&size=small${
                      p.china ? '&country=cn' : ''
                    }`}
                    alt={`${p.appKey}_icon`}
                    loading="lazy"
                    height={28}
                    width={28}
                  />
                  {prettifyName(p.name)}
                  {p.platform === 'ios' && (
                    <AppleIcon className="ms-1" size={20} />
                  )}
                  {p.platform === 'android' && (
                    <AndroidIcon className="ms-1" size={20} />
                  )}
                  {p.platform === 'web' && (
                    <BrowserChrome
                      className="ms-1"
                      size={15}
                      color={SecondaryColor}
                    />
                  )}
                  {p.china && <ChinaIcon className="ms-1" size={20} />}
                </DropdownItem>
              </div>
            ))}
          <Dropdown.Divider style={{ color: '#c1c1c1', border: 'none' }} />
          <div className="position-relative">
            <div className="searchArea px-3">
              <SearchInputComponent
                setValue={setProjectSwitcherSearch}
                datatestid={'txtProjectHeaderGameSwitcherSearch'}
              />
            </div>

            <div
              style={{
                overflowY: 'scroll',
                minHeight: 60,
                maxHeight: 450,
              }}
            >
              {filterProjectsToSwitch(
                projectsToSwitch.filter(
                  (p) => !projectHeader?.gamePack.includes(p.appKey)
                ),
                projectSwitcherSearch
              ).map((p, index) => (
                <div
                  key={p.id}
                  className={index === 0 ? 'firstElemPadding' : ''}
                >
                  <DropdownItem
                    data-testid={`lnkProjectHeaderGameSwitcher${p.id}`}
                    as={Link}
                    to={`/projects/${p.id}`}
                    className="d-inline-flex align-items-center"
                  >
                    <img
                      className="appIcon me-2"
                      src={`https://storeapi.saygames.io/api/v1/icon?appkey=${
                        p.appKey
                      }&return_empty_on_error=true&size=small${
                        p.china ? '&country=cn' : ''
                      }`}
                      alt={`${p.appKey}_icon`}
                      loading="lazy"
                      height={28}
                      width={28}
                    />
                    <div className="text-break d-flex align-items-center">
                      {prettifyName(p.name)}
                      {p.platform === 'ios' && (
                        <AppleIcon className="ms-1" size={20} />
                      )}
                      {p.platform === 'android' && (
                        <AndroidIcon className="ms-1" size={20} />
                      )}
                      {p.platform === 'web' && (
                        <BrowserChrome
                          className="ms-1"
                          size={15}
                          color={SecondaryColor}
                        />
                      )}
                      {p.china && <ChinaIcon className="" size={20} />}
                    </div>
                  </DropdownItem>
                </div>
              ))}
            </div>
          </div>
        </DropdownButton>
      </div>
      <div
        className="additionalInfoSection"
        onMouseOver={() => setHoverStoreLink(true)}
        onMouseLeave={() => setHoverStoreLink(false)}
      >
        <span className="text-muted ms-3">{projectHeader.appKey}</span>
        <span className="text-muted ms-3">{projectHeader.company}</span>
        {projectHeader.storeUrl !== '' && (
          <a
            data-testid="lnkProjectHeaderGameSwitcherStoreUrl"
            className="d-flex align-items-center ms-3 text-decoration-none"
            href={projectHeader.storeUrl}
            rel="noreferrer"
            target="_blank"
          >
            {projectHeader.platform === 'ios' && (
              <StyledStoreImg
                src="/img/app_store_logo.png"
                alt="app_store_logo"
                height={22}
                width={22}
                hover={hoverStoreLink}
              />
            )}
            {projectHeader.platform === 'android' &&
              projectHeader.storeUrl.includes('play.google.com') && (
                <StyledStoreImg
                  src="/img/play_google_logo.png"
                  alt="play_google_logo"
                  height={22}
                  width={22}
                  hover={hoverStoreLink}
                />
              )}
            {projectHeader.platform === 'android' &&
              projectHeader.storeUrl.includes('amazon') && (
                <StyledStoreImg
                  src="/img/amazon_store_icon.png"
                  alt="amazon_store_logo"
                  height={22}
                  width={22}
                  hover={hoverStoreLink}
                />
              )}
            {projectHeader.platform === 'android' &&
              projectHeader.storeUrl.includes('huawei') && (
                <StyledStoreImg
                  src="/img/huawei_store_icon.png"
                  alt="huawei_store_logo"
                  height={22}
                  width={22}
                  hover={hoverStoreLink}
                />
              )}
            <span className="text-muted ms-2">{projectHeader.storeTitle}</span>
          </a>
        )}
      </div>
    </StyledGameSwitcher>
  );
};

const NavbarView: React.FC = () => {
  const { projectId, versionId } = useParams();
  const { pathname } = useLocation();
  const { hasPermission, isAdmin } = useAuth();
  const projectHeader = useContext(ProjectCtx);

  const collectLinks = () => {
    let list = [
      {
        eventKey: '1',
        label: 'Versions',
        datatestid: 'lnkProjectHeaderVersions',
        to: '',
        active: pathname === `/projects/${projectId}`,
      } as IProjectHeaderNavLinkObject,
    ];

    if (
      isAdmin() ||
      (hasPermission(PERMISSION_READ_EXPERIMENTS) && projectHeader.experiments)
    ) {
      list.push({
        eventKey: '2',
        label: 'Experiments',
        datatestid: 'lnkProjectHeaderExperiments',
        to: 'experiments',
        active: pathname.includes(`/projects/${projectId}/experiments`),
      } as IProjectHeaderNavLinkObject);
    }

    if (projectHeader.globalLocalization) {
      list.push({
        eventKey: '3',
        label: 'Localization',
        datatestid: 'lnkProjectHeaderLocalizations',
        to: 'game_messages',
        active: pathname.startsWith(`/projects/${projectId}/game_messages`),
      } as IProjectHeaderNavLinkObject);
    }

    list.push({
      eventKey: '4',
      label: 'Devices',
      datatestid: 'lnkProjectHeaderDevices',
      to: 'devices',
      active: pathname.startsWith(`/projects/${projectId}/devices`),
    } as IProjectHeaderNavLinkObject);

    if (hasPermission(PERMISSION_SAYKIT_SETTINGS)) {
      list.push({
        eventKey: '5',
        label: 'SayKit',
        datatestid: 'lnkProjectHeaderSayKitSettings',
        to: 'saykit-settings',
        active: pathname.startsWith(`/projects/${projectId}/saykit-settings`),
      } as IProjectHeaderNavLinkObject);
    }

    list.push({
      eventKey: '6',
      label: 'Builds',
      datatestid: 'lnkProjectHeaderBuilds',
      to: 'builds',
      active: pathname.startsWith(`/projects/${projectId}/builds`),
    } as IProjectHeaderNavLinkObject);

    if (hasPermission(PERMISSION_PROJECT_SETTINGS)) {
      list.push({
        eventKey: '7',
        label: 'Settings',
        datatestid: 'lnkProjectHeaderSettings',
        to: 'settings',
        active: pathname.startsWith(`/projects/${projectId}/settings`),
      } as IProjectHeaderNavLinkObject);
    }

    if (hasPermission(PERMISSION_MONETIZATION)) {
      list.push({
        eventKey: '8',
        label: 'Monetization',
        datatestid: 'lnkProjectHeaderMonetization',
        to: 'monetization',
        active: pathname.startsWith(`/projects/${projectId}/monetization`),
      } as IProjectHeaderNavLinkObject);
    }

    if (hasPermission(PERMISSION_CPI)) {
      list.push({
        eventKey: '9',
        label: 'CPIs',
        datatestid: 'lnkProjectHeaderCPI',
        to: 'cpi',
        active: pathname.startsWith(`/projects/${projectId}/cpi`),
      } as IProjectHeaderNavLinkObject);
    }

    if (hasPermission(PERMISSION_SUPPORT)) {
      list.push({
        eventKey: '10',
        label: 'Support',
        datatestid: 'lnkProjectHeaderSupport',
        to: 'support',
        active: pathname.startsWith(`/projects/${projectId}/support`),
      } as IProjectHeaderNavLinkObject);
    }

    if (hasPermission(PERMISSION_NOTIFICATIONS)) {
      list.push({
        eventKey: '11',
        label: 'Notifications',
        datatestid: 'lnkProjectHeaderNotifications',
        to: 'notifications',
        active: pathname.startsWith(`/projects/${projectId}/notifications`),
      } as IProjectHeaderNavLinkObject);
    }

    list.push(
      {
        eventKey: '12',
        label: 'User Issues',
        datatestid: 'lnkProjectHeaderUserIssues',
        to: 'user-issues',
        active: pathname.startsWith(`/projects/${projectId}/user-issues`),
      } as IProjectHeaderNavLinkObject,
      {
        eventKey: '13',
        label: 'Log Validation',
        datatestid: 'lnkProjectHeaderLoggingCheck',
        to: 'log-validation',
        active: pathname.startsWith(`/projects/${projectId}/log-validation`),
      } as IProjectHeaderNavLinkObject,
      {
        eventKey: '14',
        label: 'Inapps',
        datatestid: 'lnkProjectHeaderInapps',
        to: 'inapps',
        active: pathname.startsWith(`/projects/${projectId}/inapps`),
      } as IProjectHeaderNavLinkObject
    );

    if (hasPermission(PERMISSION_CI_DI)) {
      list.push({
        eventKey: '15',
        label: 'CI/DI',
        datatestid: 'lnkProjectHeaderCidi',
        to: 'cidi',
        active: pathname.startsWith(`/projects/${projectId}/cidi`),
      } as IProjectHeaderNavLinkObject);
    }

    return list;
  };

  const links = collectLinks();
  const [visibleLinks, setVisibleLinks] = useState(links);
  const [hiddenLinks, setHiddenLinks] = useState(
    [] as IProjectHeaderNavLinkObject[]
  );
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const linkRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (navbarRef.current) {
        const navbarWidth = navbarRef.current.offsetWidth - 16;
        let totalWidth = 0;
        let newVisibleLinks: IProjectHeaderNavLinkObject[] = [];
        let newHiddenLinks: IProjectHeaderNavLinkObject[] = [];

        links.forEach((link, index) => {
          let linkWidth = linkRefs.current[index]?.offsetWidth || 0;
          totalWidth += linkWidth;
          newVisibleLinks.push(link);
        });

        if (totalWidth > navbarWidth) {
          while (totalWidth + 65 > navbarWidth && newVisibleLinks.length > 0) {
            newHiddenLinks = [newVisibleLinks.pop()!, ...newHiddenLinks];
            totalWidth -=
              linkRefs.current[newVisibleLinks.length]?.offsetWidth || 0;
          }
        }

        setVisibleLinks(newVisibleLinks);
        setHiddenLinks(newHiddenLinks);
      }
    };

    handleResize(); // Initial calculation
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledNavbar
      as={Navbar}
      ref={navbarRef}
      isstyledborder={versionId !== undefined ? 1 : 0}
    >
      <Nav>
        {visibleLinks.map((link: IProjectHeaderNavLinkObject, idx: number) => (
          <Nav.Link
            as={Link}
            key={`${link.eventKey}_${link.label}`}
            eventKey={link.eventKey}
            data-testid={link.datatestid}
            to={link.to}
            active={link.active}
            ref={(el: any) => (linkRefs.current[idx] = el)}
          >
            {link.label}
          </Nav.Link>
        ))}
        {hiddenLinks.length > 0 && (
          <NavDropdown title="More">
            {hiddenLinks.map((link) => (
              <Nav.Link
                as={Link}
                key={`${link.eventKey}_${link.label}`}
                eventKey={link.eventKey}
                data-testid={link.datatestid}
                to={link.to}
                active={link.active}
              >
                {link.label}
              </Nav.Link>
            ))}
          </NavDropdown>
        )}
      </Nav>
    </StyledNavbar>
  );
};

const StyledNavbar = styled.div<{ isstyledborder: number }>`
  background: #f0f2f4;
  margin-top: 1rem;
  padding: 0.75rem 1rem;

  border-radius: ${(props: { isstyledborder: number }) =>
    props.isstyledborder === 1 ? '16px 16px 16px 0px' : '16px 16px 16px 16px'};

  a.nav-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.35rem;
    text-decoration: none;
    color: #6c757d;
  }

  .dropdown-menu {
    background: #f0f2f4;
    border-radius: 8px;
    left: -4.1rem;
  }
`;

const StyledGameSwitcher = styled.div`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;

    .additionalInfoSection {
      display: flex;
      align-items: center;
      margin-left: 2.2rem;
      height: 20px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    .additionalInfoSection {
      display: flex;
      align-items: center;
      height: 45px;
    }
  }

  .appIcon {
    border-radius: 10px;
  }

  .selectableDropdown {
    .btn-outline-secondary {
      background-color: #fff;
      color: black;
      height: 45px;
      border: 1px transparent;
      border-radius: 10px;
      padding: 0 10px 0 0;
      box-shadow: none;

      &:hover {
        background-color: #e9ecef;
      }
    }

    @keyframes slideIn {
      from {
        height: 0;
        opacity: 0;
      }
      to {
        height: 530px;
        opacity: 1;
      }
    }

    .dropdown-menu {
      animation: slideIn 0.5s ease;
      max-height: 530px;
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
      border-radius: 8px;
      width: 100%;
      overflow-y: hidden;
    }

    .dropdown-toggle {
      border: none;
    }

    .dropdown-toggle::after {
      position: absolute;
      right: 5px;
      top: 22px;
    }
  }

  .searchArea {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: -2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
  }

  .firstElemPadding {
    padding-top: 60px;
  }
`;

const StyledStoreImg = styled.img<{ hover: boolean }>`
  margin-left: 0.5rem;
  filter: ${(props) => (props.hover ? 'none' : 'grayscale(100%)')};
`;
